import React from "react";

import '../css/tickets.css'

var XMAS = 'https://sochi.qtickets.events/142944-secret-party'
var CODE = 'https://sochi.qtickets.events/135589-code-1080-bolshoe-vystuplenie'
var TELEGRAM = 'https://qtickets.ru/event/146004?base_color=940000'

function Button(props) {
  return(
    <a target={"_blank"} rel="noreferrer" href={props.link} className="ticketBtn">{props.text}</a>
  );
}

function Tickets() {
  return(
    <div className="containerTickets" id="ticket">
      <h1 className="ticketsToBuy">Чтобы купить билет</h1>
      <h1 className="ticketsChoise">Выбери мероприятие</h1>
      <div className="ticketsContainer">
        <Button link={XMAS} text="X-MAS"></Button>
        <Button link={CODE} text="CODE80"></Button>
        <Button link={TELEGRAM} text="IVAN ZOLO"></Button>
      </div>
      <p>*Если кнопка не работает, то билеты приобретаются на входе</p>
    </div>
  )
}

export default Tickets;